import React, { useState } from 'react';
import { TextField, useRedirect, ReferenceArrayInput } from 'react-admin';

import BlockIcon from '@material-ui/icons/Block';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import SharedWarehousesList from './components/SharedWarehousesList';
import SharedIpAddressesList from './components/SharedIPAddressList';
import SharedFingerprintsList from './components/SharedFingerprintsList';
import { Datagrid, List, Filter } from 'components/list';
import { ActionField, DateField } from 'components/fields';
import EnableUserAction from './components/EnableUserAction';
import EditFraudDetailsDialogAction from './components/EditFraudDetailsDialogAction';
import BypassFraudAction from './components/BypassFraudAction';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import NameWithEmailField from './components/NameWithEmailField';
import GlobalResetPasswordButton from './components/GlobalResetPasswordButton';
import MultipleWarehousesList from './components/MultipleWarehousesList';
import MultipleCardsList from './components/MultipleCardsList';

import { Select } from 'components/form';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 700,
  },

  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: theme.spacing(3),
  },

  form: {
    width: '50%',
    '& .filter-field': {
      width: '100%',
    },
  },
}));

enum TabValues {
  FromAddress = 'FromAddress',
  Phone = 'Phone',
  IpAddress = 'IpAddress',
  Fingerprints = 'Fingerprints',
  MultipleWarehouses = 'MultipleWarehouses',
  MultipleCards = 'MultipleCards',
}

const flagStates = [
  {
    value: 'any',
    label: 'Any',
  },
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

const BlockedUsersFilter = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.filterContainer}>
      <Typography className={classes.title}>Recently blocked</Typography>
      <Filter withSearch {...props} searchKey="email" classes={classes} showChips={false}>
        <Select
          options={flagStates}
          source="phoneVerified"
          name="phoneVerified"
          label="Phone verification status"
        />

        <ReferenceArrayInput
          icon={BlockIcon}
          basePath={props.basePath}
          label="Suspend reason"
          source="fraud.reason"
          reference="fraudTypes"
          allowEmpty>
          <ChipsArrayInput optionValue="code" optionLabel="name" />
        </ReferenceArrayInput>

        <Select source="blocked" name="blocked" label="Blocked" options={flagStates} />

        <Select source="fraud" name="fraud" label="Marked as fraud" options={flagStates} />
      </Filter>
    </Box>
  );
};

const FraudUsersList = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const [type, setType] = useState<TabValues>(TabValues.FromAddress);

  const handleChangeTab = (event, value) => {
    setType(value);
  };

  return (
    <>
      <Box className={classes.filterContainer}>
        <Typography className={classes.title}>Shared</Typography>
        <GlobalResetPasswordButton />
      </Box>
      <Tabs value={type} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}>
        <Tab value={TabValues.FromAddress} label="From address" />
        <Tab value={TabValues.IpAddress} label="Ip address" />
        <Tab value={TabValues.Fingerprints} label="Fingerprint" />
        <Tab value={TabValues.MultipleWarehouses} label="Multiple warehouses" />
        <Tab value={TabValues.MultipleCards} label="Multiple cards" />
      </Tabs>

      {type === TabValues.FromAddress && <SharedWarehousesList {...props} />}

      {type === TabValues.IpAddress && <SharedIpAddressesList {...props} />}

      {type === TabValues.Fingerprints && <SharedFingerprintsList {...props} />}

      {type === TabValues.MultipleWarehouses && <MultipleWarehousesList {...props} />}

      {type === TabValues.MultipleCards && <MultipleCardsList {...props} />}

      <List
        {...props}
        resource="fraudDashboard"
        filterDefaultValues={{
          blocked: 'true',
          fraud: 'any',
          phoneVerified: 'any',
        }}
        sort={{ field: 'fraudBlockedAt', order: 'DESC' }}
        filters={<BlockedUsersFilter />}>
        <Datagrid
          rowClick={(id) => {
            redirect('show', '/users', id);
          }}>
          <TextField source="id" name="id" />

          <NameWithEmailField
            label="Name"
            firstNameSource="firstName"
            lastNameSource="lastName"
            emailSource="email"
          />

          <TextField source="phone" label="Phone" />

          <DateField source="createdAt" label="Registered" />

          <DateField source="fraudDetectedAt" label="Fraud flag set" />

          <TextField source="fraudReason" label="Suspension reason" />

          <TextField source="blockReason" label="Manual block reason" />

          <TextField source="note" label="Notes" />

          <ActionField>
            <EnableUserAction />
            <BypassFraudAction />
            <EditFraudDetailsDialogAction />
          </ActionField>
        </Datagrid>
      </List>
    </>
  );
};

export default FraudUsersList;
